import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {grey} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
    ck_u_password,
    ck_u_username,
    defDateFormat,
    empty,
    outlet_visit_report_url,
    req_cust,
    req_cust_area,
    req_module_setting,
    req_report_outletvisit,
    req_salesman
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import format from 'date-fns/format';
import {getSetting} from '../../Helper/Helper';
import useCookieHook from "../../Services/useCookieHook";
import MUIDataTable from 'mui-datatables';
import {IconButton, Tooltip} from '@material-ui/core';
import {Assessment as AssessmentIcon} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	}, card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	alignRight: {
		display: 'flex',
		marginBottom: 10,
		flex: 1,
		flexDirection: 'row'
	}
}));

const tableHead = [
	{
		name: "mobile_checkin_id",
		label: "Check-In ID",
		options: {
			display: true
		}
	},
	{
		name: "cust_code",
		label: "Outlet Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Outlet Name",
		options: {
			display: true
		}
	},
	{
		name: "cust_area",
		label: "Outlet Area",
		options: {
			display: true
		}
	},
	{
		name: "company",
		label: "Branch",
		options: {
			display: true,
			customBodyRender: (value) => value ? value.toUpperCase() : ''
		}
	},
	{
		name: "name",
		label: "Agent Name",
		options: {
			display: true
		}
	},
	{
		name: "checkin_date",
		label: "Check-In Date",
		options: {
			display: true
		}
	},
	{
		name: "checkin_time",
		label: "Check-In Time",
		options: {
			display: true
		}
	},
	{
		name: "checkout_date",
		label: "Check-Out Date",
		options: {
			display: true
		}
	},
	{
		name: "checkout_time",
		label: "Check-Out Time",
		options: {
			display: true
		}
	},
	{
		name: "state",
		label: "State",
		options: {
			display: true
		}
	},
	{
		name: "region",
		label: "Region",
		options: {
			display: true
		}
	},
	{
		name: "city",
		label: "City",
		options: {
			display: true
		}
	},
	{
		name: "time_spent",
		label: "Time Spent",
		options: {
			display: true
		}
	},
	{
		name: "stock_take_count",
		label: "No. Stock Take Item",
		options: {
			display: true
		}
	}
]

export default function ROutletVisit() {
	const commonStyle = useCommonStyles();
	const classes = useStyles();

	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [nClient, setClient] = useState(gState.client);

	const [splist, setSPlist] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [regionList, setRegionList] = useState([]);
	const [outletVisitList, setOutletVisitList] = useState([]);

	const [outletVisitDateRange, setOutletVisitDateRange] = useState({});
	const [openOutletVisitDateDoc, setOpenOutletVisitDateDoc] = useState(false);
	const [outletVisitSpView, setOutletVisitSpView] = useState(false);
	const [outletVisitSpSelect, setOutletVisitSpSelected] = useState(empty);
	const [outletVisitSalesperson, setOutletVisitSalesperson] = useState(empty);
	const [outletVisitRegionView, setoutletVisitRegionView] = useState(false);
	const [outletVisitStateView, setoutletVisitStateView] = useState(false);
	const [outletVisitStateSelected, setoutletVisitStateSelected] = useState(empty);
	const [outletVisitState, setoutletVisitState] = useState(empty);
	const [outletVisitRegionSelected, setoutletVisitRegionSelected] = useState(empty);
	const [outletVisitRegion, setoutletVisitRegion] = useState(empty);
	const [outletVisitOutletView, setOutletVisitOutletView] = useState(false);
	const [outletVisitOutlet, setOutletVisitOutlet] = useState(empty);
	const [outletVisitOutletSelect, setOutletVisitOutletSelected] = useState(empty);
	const [tableHeader, setTableHeader] = useState([]);

	const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			getCountry(gState.client);
			getAreaList(gState.client);
		}
		setTableHeader(tableHead);
	}, [gState.client, gState.translation]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const getOutletVisit = (_client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(outletVisitDateRange)) {
			st_date = format(outletVisitDateRange.startDate, defDateFormat);
			st_date_to = format(outletVisitDateRange.endDate, defDateFormat);
		}
		var parameters = {
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: outletVisitSpSelect,
			cust_code: outletVisitOutletSelect,
			region: outletVisitRegion,
			state: outletVisitStateSelected,
			area: selectedArea,
			username: cookies(ck_u_username)
		};
		if (gState.company) {
			parameters.company = gState.company;
		}
		const param = axiosURIencode(parameters);
		API_Service.get(`${req_report_outletvisit}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					var _arr = extraData[idx];
					if (_arr['checkin_time']) {
						_arr['checkin_date'] = moment(_arr['checkin_time']).format('DD/MM/YYYY');
						_arr['checkin_time'] = moment(_arr['checkin_time']).format('HH:mmA');
					}
					if (_arr['checkout_time']) {
						_arr['checkout_date'] = moment(_arr['checkout_time']).format('DD/MM/YYYY');
						_arr['checkout_time'] = moment(_arr['checkout_time']).format('HH:mmA');
					}
					var time_spent_hour = _arr['time_spent_hour'];
					var time_spent_minutes = _arr['time_spent_minutes'];
					var time_spent = '';
					if (time_spent_hour !== '0') {
						time_spent = time_spent_hour;
						if (time_spent_hour === '1') {
							time_spent += ' hour ';
						} else {
							time_spent += ' hours ';
						}
					}
					if (time_spent_minutes !== '0') {
						time_spent += time_spent_minutes;
						if (time_spent_minutes === '1') {
							time_spent += ' minute ';
						} else {
							time_spent += ' minutes ';
						}
					}
					_arr['time_spent'] = time_spent;
					_arr['checkout_coordinates'] = _arr['checkout_lat'] + ',' + _arr['checkout_lng'];
					_data.push(_arr);
				}
				setOutletVisitList(outletVisitList => (extraData));
			} else {
				setOutletVisitList(outletVisitList => ([]));
				snack(language.no_data, 'warning');
			}
		});
	}

	const getCountry = (_client = nClient) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const country = getSetting(extraData, 'country');
				let country_arr = [JSON.parse(JSON.stringify(country))];
				var status = country_arr[0].states;
				var region = country_arr[0].regions;
				var _status = [];
				var _region = [];
				for (let i = 0; i < status?.length; i++) {
					const {name} = status[i];
					_status.push({name: name, secondary: name, value: name, status: 1});
				}
				for (let i = 0; i < region?.length; i++) {
					const {name} = region[i];
					_region.push({name: name, secondary: name, value: name, status: 1});
				}
				setStateList(_status);
				setRegionList(_region);
				const _config = getSetting(extraData, 'customization', {});
			} else {
				snack(language.not_found_salesperson, 'warning');
			}
		});
	}

	const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {area_name, area_code, area_status} = extraData[idx];
					_data.push({name: area_name, secondary: area_code, value: area_code, status: area_status});
				}
				setAreaList(_data);
			}
		});
	}

	const AreaViewClick = () => {
		setAreaView(true);
	}

	const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

	const getSalesmanList = (_client = nClient) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			} else {
				snack(gState.translation.not_found_salesperson, 'warning');
			}
		});
	}

	const getOutletList = (_client = nClient) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_cust}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

	const OutletVisitSPViewClick = () => {
		setOutletVisitSpView(true);
	}

	const OutletVisitSPViewClose = (newValue) => {
		setOutletVisitSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setOutletVisitSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setOutletVisitSalesperson(spname);
		}
	}

	const OutletVisitRegionViewClick = () => {
		setoutletVisitRegionView(true);
	}

	const OutletVisitRegionViewClose = (newValue) => {
		setoutletVisitRegionView(false);
		if (newValue) {
			var regionvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					regionvalue += newValue[i].value;
				} else {
					regionvalue += newValue[i].value + ",";
				}
			}
			setoutletVisitRegionSelected(regionvalue);

			var regionname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					regionname += newValue[i].name;
				} else {
					regionname += newValue[i].name + ",";
				}
			}
			setoutletVisitRegion(regionname);
		}
	}

	const OutletVisitStateViewClick = () => {
		setoutletVisitStateView(true);
	}

	const OutletVisitStateViewClose = (newValue) => {
		setoutletVisitStateView(false);
		if (newValue) {
			var statevalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					statevalue += newValue[i].value;
				} else {
					statevalue += newValue[i].value + ",";
				}
			}
			setoutletVisitStateSelected(statevalue);

			var statename = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					statename += newValue[i].name;
				} else {
					statename += newValue[i].name + ",";
				}
			}
			setoutletVisitState(statename);
		}
	}

	const OutletVisitOutletViewClick = () => {
		setOutletVisitOutletView(true);
	}

	const OutletVisitOutletViewClose = (newValue) => {
		setOutletVisitOutletView(false);
		if (newValue) {
			var custvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custvalue += newValue[i].value;
				} else {
					custvalue += newValue[i].value + ",";
				}
			}
			setOutletVisitOutletSelected(custvalue);

			var custname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custname += newValue[i].name;
				} else {
					custname += newValue[i].name + ",";
				}
			}
			setOutletVisitOutlet(custname);
		}
	}

	const getOutletVisitDate = () => {
		return !_.isEmpty(outletVisitDateRange) ? moment(outletVisitDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(outletVisitDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

	const generateOutletVisitReport = () => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(outletVisitDateRange)) {
			st_date = format(outletVisitDateRange.startDate, defDateFormat);
			st_date_to = format(outletVisitDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`;
		const branch = gState.company ? gState.company : '';
		var link = JString(outlet_visit_report_url)
			.replaceInfinity('@client', client)
			.replaceInfinity('@reportDate', encodeURIComponent(date_range))
			.replaceInfinity('@salespersonId', outletVisitSpSelect)
			.replaceInfinity('@cust_code', outletVisitOutletSelect)
			.replaceInfinity('@state', outletVisitStateSelected)
			.replaceInfinity('@region', outletVisitRegionSelected)
			.replaceInfinity('@branch', branch)
			.replaceInfinity('@username', cookies(ck_u_username))
			.str;
		window.open(link, "_blank");
	}


	return (
		<div>
			{/* <Typography className={classes.heading}>{ language.generate_outlet_visit_report }</Typography> */}
			<div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => OutletVisitSPViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletVisitSalesperson ? _.truncate(outletVisitSalesperson, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => OutletVisitOutletViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletVisitOutlet ? _.truncate(outletVisitOutlet, {length: 20}) : language.customer}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => AreaViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => OutletVisitRegionViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletVisitRegion ? _.truncate(outletVisitRegion, {length: 20}) : language.region}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => OutletVisitStateViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletVisitState ? _.truncate(outletVisitState, {length: 20}) : language.state}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOpenOutletVisitDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getOutletVisitDate()}
					</Typography>
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getOutletVisit()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
			</div>
			<div style={{height: 20}}/>
			{
				outletVisitList.length > 0 && (
					<MUIDataTable
						title={language.outlet_visit}
						data={outletVisitList}
						columns={tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 40, 65, 100],
							responsive: 'scrollMaxHeight',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
									case 'remove':
										tableHeader[index].options.display = false;
								}
								setTableHeader(tableHeader);
							},
							customToolbar: () =>
								<>
									<CheckinTableReportButton onGenerateReport={generateOutletVisitReport}/>
								</>
						}}
					/>
				)
			}

			<VirtualListCB
				open={outletVisitSpView}
				setClose={data => OutletVisitSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={outletVisitOutletView}
				setClose={data => OutletVisitOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={outletVisitRegionView}
				setClose={data => OutletVisitRegionViewClose(data)}
				title={"Region"}
				data={regionList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitStateView}
				setClose={data => OutletVisitStateViewClose(data)}
				title={"State"}
				data={stateList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
				filteration={0}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setOutletVisitDateRange(range);
					setOpenOutletVisitDateDoc(false)
				}}
				setNegative={() => {
					setOpenOutletVisitDateDoc(false)
				}}
				setClose={() => {
					setOpenOutletVisitDateDoc(false)
				}}
				open={openOutletVisitDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>
		</div>
	)
}

function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
